import 'vanilla-cookieconsent';
// import './collapsible';
// import { init as initMap } from './map';

// initMap();

const cookieconsent = window.initCookieConsent();
cookieconsent.run({
  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies',
        description:
          'Hi, this website uses essential cookies to ensure its proper operation and analytical cookies to understand how you interact with it.',
        primary_btn: {
          text: 'Accept',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Settings',
          role: 'settings', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all', // optional, [v.2.5.0 +]
        blocks: [
          {
            title: 'Cookie usage',
            description:
              'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
          },
          {
            title: 'Strictly necessary cookies',
            description:
              'These cookies are essential for the proper functioning of the website.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            },
          },
          {
            title: 'Analytics cookies',
            description:
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Marketing cookies',
            description:
              'These cookies are necessary for the proper functioning of the newsletter signup form.',
            toggle: {
              value: 'marketing',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'More information',
            description:
              'For any queries in relation to policy on cookies and your choices, please check <a class="cc-link" target="_blank" href="https://mailchimp.com/legal/cookies/">MailChimp Cookie Statement</a> and <a class="cc-link" target="_blank" href="https://www.google.com/policies/privacy/partners">how Google uses data from sites that use their services</a>.',
          },
        ],
      },
    },
  },
  page_scripts: true,
});

const SELECTORS = {
  navbarBurger: '.navbar-burger',
  navbarMenu: '.navbar-menu',
};

document.addEventListener('DOMContentLoaded', () => {
  setupNavbarBurgerBehavior();
  setupNavbarBehavior();
});

function setupNavbarBurgerBehavior() {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(SELECTORS.navbarBurger),
    0
  );

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }
}

function setupNavbarBehavior() {
  const $mobileMenu = Array.prototype.slice.call(
    document.querySelectorAll(SELECTORS.navbarMenu),
    0
  );

  if ($mobileMenu.length > 0) {
    $mobileMenu.forEach((el) => {
      el.addEventListener('click', (event) => {
        const clickedAnchor = event.target.closest('a');
        const isLocalAnchor = clickedAnchor.getAttribute('href').includes('#');
        if (!isLocalAnchor) {
          // We don't need this behavior if the page is reloading
          return;
        }
        document
          .querySelectorAll(SELECTORS.navbarBurger)
          .forEach((navbarBurger) => {
            navbarBurger.classList.remove('is-active');
          });

        el.classList.remove('is-active');
      });
    });
  }
}
